<template>
    <div class="misc-wrapper">
        <b-link class="brand-logo">
            <logo-with-text />
        </b-link>
        <div class="misc-inner p-2 p-sm-3">
        <div class="w-100 text-center">
            <div class="mb-1">
                <dx-util-load-indicator
                  :visible="loadIndicatorVisible"
                  :indicator-src="`${require('@/assets/images/svg/bars.svg')}`"
                  class="button-indicator"
                />
                <h4>
                  {{ message }}
                </h4>
            </div>
            <b-button v-if="isActivated" variant="primary" class="mb-1 btn-sm-block" type="button" @click="onClickRedirectLogin">
                You can now login
            </b-button>
            <b-img fluid :src="require('@/assets/images/undraw/completed_tasks.svg')" alt="Account Activated" />
        </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable global-require */
/* eslint-disable no-unused-vars */
import userAccountService from '@/http/requests/system/userAccountService'
import LogoWithText from '@core/layouts/components/LogoWithText.vue'

export default {
  components: {
    LogoWithText,
  },
  data() {
    return {
      query: {},
      isActivated: false,
      loadIndicatorVisible: false,
      message: 'This may take some time. Please wait.',
    }
  },
  computed: {

  },
  mounted() {
    this.activateAccount()
  },
  created() {
    this.query = { ...this.$route.params }
  },
  methods: {
    async activateAccount() {
      this.loadIndicatorVisible = true
      let code = ''
      if (this.query.code) {
        code = this.query.code
        userAccountService.activateAccount(code).then(result => {
          if (result.status === 200) {
            this.isActivated = true
            this.message = 'Your Account has been activated successfully'
          }
        }).catch(error => {
          const errorList = [...error.data.meta.error_list]
          this.message = errorList[0].message
          if (error.data.meta.return_code === 26012) {
            this.isActivated = true
          }
        }).finally(() => {
          this.loadIndicatorVisible = false
        })
      }
    },
    onClickRedirectLogin() {
      this.$router.push({ name: 'auth-login' })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
